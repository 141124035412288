/*
 |-----------------------------------------------------------------------------
 | components/atoms/Hamburger/Hamburger.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.block {
	$size: $tap-area;

	@include flex('center', 'center');
	min-height: $size;
	min-width: $size;

	&:hover {
		.stack {
			// TODO: State required

			&::after,
			&::before {
				// TODO: State required
			}
		}
	}

	&:focus {
		.stack {
			// TODO: State required

			&::after,
			&::before {
				// TODO: State required
			}
		}
	}

	&:active {
		.stack {
			// TODO: State required

			&::after,
			&::before {
				// TODO: State required
			}
		}
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isDisabled {
		@include disabled();
	}
}

.stack {
	$color: $neutral-100;

	@include display('block', $grid-gap, div($grid-gap, 8));
	@include transition('background-color');
	background-color: $color;
	border-radius: $curvature;
	position: relative;

	&::after,
	&::before {
		@include absolute($left: 0);
		@include display('block', $grid-gap, div($grid-gap, 8));
		background-color: $color;
		border-radius: $curvature;
		content: '';
	}

	&::after {
		top: 9px;
	}

	&::before {
		top: -9px;
	}
}

.dark {
	.stack {
		$color: $neutral-100;

		background-color: $color;

		&::after,
		&::before {
			background-color: $color;
		}
	}
}

.light {
	.stack {
		$color: $neutral-0;

		background-color: $color;

		&::after,
		&::before {
			background-color: $color;
		}
	}
}
