/*
 |-----------------------------------------------------------------------------
 | components/molecules/HeaderMenu/HeaderMenu.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.icon {
	$size: 14px;

	@include transition('opacity');
	display: inline-block;
	fill: currentColor;
	height: $size;
	margin-left: div($grid-gap, 8);
	opacity: 0.25;
	width: $size;
}

.lvl0 {
	@include flex('space-between', 'center');

	> li {
		flex: 1 0 auto;
		margin: 0 div($grid-gap, 2);
		position: relative;
	}

	.item,
	.parent {
		font-size: rems(20px);

		&:hover {
			.icon {
				opacity: 1;
			}
		}

		/* stylelint-disable-next-line selector-class-pattern */
		&.isCurrent {
			cursor: default;
		}
	}
}

.lvl1 {
	@include absolute($top: 100%, $left: 0);
	@include display('none', 225%);
	@include z-index('above');

	> li {
		margin: div($grid-gap, 2) 0;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isVisible {
		display: block;
	}
}

.dark {
	&.lvl0 {
		.item,
		.parent {
			@include hyperlink($brand-3, $brand-1);

			/* stylelint-disable-next-line selector-class-pattern */
			&.isCurrent {
				/* stylelint-disable-next-line max-nesting-depth */
				&::after {
					transform: scaleX(1);
					visibility: visible;
				}
			}
		}
	}
}

.light {
	&.lvl0 {
		.item,
		.parent {
			@include hyperlink($neutral-0, $brand-1);

			/* stylelint-disable-next-line selector-class-pattern */
			&.isCurrent {
				/* stylelint-disable-next-line max-nesting-depth */
				&::after {
					transform: scaleX(1);
					visibility: visible;
				}
			}
		}
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.offCanvas {
	display: block;
	margin-top: $grid-gap;

	li {
		margin: 0;
	}

	&.lvl0 {
		.item,
		.parent {
			@include font($size: rems(24px), $weight: 'light');
			color: $brand-4;

			&::after {
				display: none;
			}
		}
	}

	.lvl1 {
		background-color: #383838;
		position: static;

		.item {
			@include hyperlink($brand-2, $brand-2);
		}
	}

	.item {
		display: block;
		padding: div($grid-gap, 2) $grid-gap;
	}

	.parent {
		display: block;
		padding: div($grid-gap, 2) $grid-gap;
	}
}
