/*
 |-----------------------------------------------------------------------------
 | components/molecules/SkipLink/SkipLink.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	@include display('block', 1px, 1px);
	@include z-index('beyond');
	border-radius: 0 0 $curvature $curvature;
	max-width: $menu-width;
	overflow: hidden;
	position: absolute;
	text-align: center;

	&:focus {
		height: auto;
		left: 0;
		margin: 0 auto;
		padding: div($grid-gap, 2);
		right: 0;
		width: auto;
	}
}

.dark {
	color: $neutral-80;
	composes: base;

	&:focus {
		background-color: $neutral-10;
	}
}

.light {
	color: $neutral-10;
	composes: base;

	&:focus {
		background-color: $neutral-80;
	}
}
