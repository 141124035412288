/*
 |-----------------------------------------------------------------------------
 | components/atoms/Copyright/Copyright.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.block {
	color: $brand-4;
	font-size: rems(14px);
}
