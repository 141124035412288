/*
 |-----------------------------------------------------------------------------
 | components/atoms/Close/Close.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | 1. Size
 | 2. Thickness
 |
 */

@import 'styles/bundle';

.block {
	$self: &;
	$size: $tap-area;

	min-height: $size;
	min-width: $size;
	position: relative;

	&::after,
	&::before {
		@include absolute($top: 50%, $left: 50%);
		@include transition('background-color');
		background-color: $neutral-0;
		content: '';
		height: 44px; /* 1 */
		width: 2px; /* 2 */
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:hover {
		&::after,
		&::before {
			// TODO: State required
		}
	}

	&:focus {
		&::after,
		&::before {
			// TODO: State required
		}
	}

	&:active {
		&::after,
		&::before {
			// TODO: State required
		}
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isDisabled {
		@include disabled();
	}
}
