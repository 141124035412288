/*
 |-----------------------------------------------------------------------------
 | components/organisms/Header/Header.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	@include fixed($top: 0, $right: 0, $left: 0);
	@include transition('all');
	@include z-index('menu');
	background-color: transparent;
	padding: div($grid-gap, 2) 0;

	/* stylelint-disable-next-line selector-class-pattern */
	&.isHidden {
		opacity: 0;
		visibility: hidden;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isSticky {
		@include sticky(-1);
		opacity: 1;
		visibility: visible;

		&:global(.extend) {
			&::after {
				@include absolute($top: 80px, $right: 0, $left: 0);
				content: '';
				height: 180px;

				@include breakpoint('tablet-p') {
					display: none;
				}
			}
		}
	}
}

.container {
	@include container();
	@include flex('space-between', 'center');
}

.logo {
	display: inline-block;

	svg {
		height: 45px;
		width: 169px;
	}
}

.wrapper {
	column-gap: $grid-gap;
	display: flex;
}

.dark {
	composes: base;

	.logo {
		fill: $brand-3;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isSticky {
		background-color: $neutral-0;

		&::after {
			background-color: $neutral-0;
		}

		.logo {
			fill: $brand-3;
		}
	}
}

.light {
	composes: base;

	.logo {
		fill: $neutral-0;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isSticky {
		background-color: $brand-3;

		&::after {
			background-color: $brand-3;
		}

		.logo {
			fill: $neutral-0;
		}
	}
}

.navigation {
	@include flex('space-between', 'center');
}

@include breakpoint('mobile-l') {
	.logo {
		svg {
			height: 37px;
			width: 140px;
		}
	}
}
