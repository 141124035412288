/*
 |-----------------------------------------------------------------------------
 | components/molecules/Form/Form.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	p {
		color: $brand-4;
		font-size: 16px;
	}
}

.enquiries {
	composes: base;

	@include grid(div($grid-gap, 4), 1.5fr 1fr 1fr);

	p {
		@include area(1, 4);
		color: $brand-1;
	}

	:global(.button) {
		justify-self: flex-start;
		margin-top: $grid-gap;
	}

	:global(.checkbox) {
		@include area(1, 4);
		align-self: center;
	}

	:global(.textarea) {
		@include area(1, 4);
	}
}

.subscriptions {
	composes: base;

	@include grid(div($grid-gap, 4) $grid-gap, $columns: 3fr 1fr);

	p {
		@include area(1, 3);
		font-size: 13px;
	}

	:global(.button) {
		align-self: flex-end;
		height: 10px;
	}

	:global(.checkbox) {
		@include area(1, 3);
	}
}

@include breakpoint('tablet-l') {
	.subscriptions {
		:global(.button) {
			padding: 0;
		}
	}
}

@include breakpoint('mobile-l') {
	.enquiries {
		grid-template-columns: 1fr;

		:global(.input) {
			@include area(1, 2);
		}
	}
}

@include breakpoint('mobile-s') {
	.subscriptions {
		grid-gap: div($grid-gap, 2) 0;
		grid-template-columns: 1fr;

		:global(.button),
		:global(.input) {
			@include area(1, 2);
		}

		:global(.button) {
			justify-self: flex-start;
			padding: 0 $grid-gap;
		}
	}
}
