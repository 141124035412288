/*
 |-----------------------------------------------------------------------------
 | components/atoms/Button/Button.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	@include font($size: rems(16px), $weight: 'medium');
	@include transition('background-color');
	border-radius: mul($curvature, 8);
	display: inline-block;
	line-height: $tap-area;
	min-height: $tap-area;
	min-width: $tap-area;
	padding: 0 $grid-gap;

	&:hover {
		.icon {
			opacity: 1;
		}
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isDisabled {
		@include disabled();
	}
}

.icon {
	$size: rems(16px);

	@include transition('opacity');
	fill: currentColor;
	height: $size;
	margin-left: div($grid-gap, 2);
	opacity: 0.25;
	width: $size;
}

.wrapper {
	@include flex('space-between', 'center');
}

.ghost {
	background-color: transparent;
	border: 1px solid;
	composes: base;
	line-height: sub($tap-area, 4px);
	min-height: sub($tap-area, 4px);
}

.primary {
	background-color: $brand-1;
	color: $neutral-0;
	composes: base;

	&:hover {
		background-color: darken($brand-1, 15%);
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}
}

.secondary {
	background-color: $brand-2;
	color: $brand-1;
	composes: base;

	&:hover {
		background-color: darken($brand-2, 15%);
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}
}

.tertiary {
	$color: $brand-4;

	border-color: $color;
	color: $color;
	composes: ghost;

	&:hover {
		background-color: $color;
		color: $brand-3;
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}
}
