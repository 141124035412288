/*
 |-----------------------------------------------------------------------------
 | components/layouts/Layout/Layout.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.content {
	// @include z-index('above');
	background-color: $neutral-0;
	min-height: 100vh;
	// overflow-x: hidden;
	position: relative;

	&::after {
		@include fixed(0, 0, 0, 0);
		@include z-index('overlay');
		background-color: $overlay;
		content: '';
		opacity: 0;
		transition: opacity 0.5s, visibility 0s 0.5s;
		visibility: hidden;
	}

	:global(.contact) &,
		/* stylelint-disable-next-line selector-class-pattern */
	:global(.thank-you) & {
		background-color: $brand-3;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&:global(.isBlurred) {
		&::after {
			opacity: 1;
			transition: opacity 0.5s;
			visibility: visible;
		}
	}
}
