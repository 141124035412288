/*
 |-----------------------------------------------------------------------------
 | components/atoms/Honeypot/Honeypot.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.block {
	display: none;
}
