/*
 |-----------------------------------------------------------------------------
 | components/molecules/FooterMenu/FooterMenu.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.block {
	li {
		&:not(:last-child) {
			margin-bottom: div($grid-gap, 2);
		}
	}
}

.icon {
	@include icon(currentColor, 12px);
	@include transition('opacity');
	fill: currentColor;
	margin-left: div($grid-gap, 8);
	opacity: 0.25;
}

.item {
	@include font($size: rems(15px), $weight: 'medium');
	@include hyperlink($brand-4);

	&:hover {
		.icon {
			opacity: 1;
		}
	}
}
