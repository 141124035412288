/*
 |-----------------------------------------------------------------------------
 | components/organisms/Footer/Footer.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.block {
	// @include sticky($right: 0, $bottom: 0, $left: 0);
	background-color: $brand-3;
	padding: $grid-gap 0;
}

.colophon {
	@include container();
	@include grid($columns: 4.5fr 1fr);
	margin-top: mul($grid-gap, 3);
}

/* stylelint-disable-next-line selector-class-pattern */
.signOff {
	@include container();
	@include grid($columns: 2.5fr 1fr 1fr 1fr);

	/* stylelint-disable-next-line selector-class-pattern */
	:global(.footerMenu) {
		margin-top: div($grid-gap, 2);
	}
}

.title {
	@include font($size: rems(15px), $weight: 'bold');
	color: $brand-2;
	margin-bottom: $grid-gap;
	text-transform: uppercase;
}

@include breakpoint('tablet-p') {
	.colophon {
		@include flex('space-between');
	}

	/* stylelint-disable-next-line selector-class-pattern */
	.signOff {
		grid-row-gap: $grid-gap;
		grid-template-columns: repeat(2, 1fr);

		div {
			&:nth-of-type(1) {
				@include area(1, 3);
			}
		}
	}
}

@include breakpoint('mobile-l') {
	.colophon {
		flex-wrap: wrap;
	}
}
