/*
 |-----------------------------------------------------------------------------
 | components/molecules/Navbar/Navbar.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

/* stylelint-disable-next-line selector-class-pattern */
.offCanvas {
	@include fixed($top: 0, $right: mul($menu-width, -1), $bottom: 0);
	@include transition('right');
	@include z-index('menu');
	background-color: $brand-3;
	width: $menu-width;

	:global(.close) {
		display: block;
		margin: $grid-gap $grid-gap 0 auto;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&:global(.isOpen) {
		right: 0;
	}
}

.storybook {
	right: 0;
}
